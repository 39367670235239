import { Environments } from './environment';

export const ROUTES = {
    auth: {
        login: '/auth/login',
    },
    dashboard: {
        products: '/products',
        cart: '/cart',
        checkout: '/checkout',
        profile: '/profile',
        orders: '/orders',
        payment: '/payment',
        wiki: ' https://docs.horysmall.com',
        support: 'https://support.horysmall.com/support/home',
        homnifyProfile: Environments.HOMNIFY.profileUrl,
        homnifyDeposit: Environments.HOMNIFY.depositUrl,
    },
};
