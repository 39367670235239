import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useCallback } from 'react';
import { useLoadingStore } from '../stores/loadingStore';
export default function CustomNextLink({
  children,
  className,
  target,
  onClick,
  ...props
}: LinkProps & {
  children?: React.ReactNode;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: () => void;
}) {
  //
  const pathname = usePathname();
  const {
    setLoading
  } = useLoadingStore();
  const handleOnClick = useCallback(() => {
    if (pathname != props.href && !props.href.toString().startsWith('http')) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [pathname, props.href, setLoading]);
  return <Link onClick={() => {
    handleOnClick();
    onClick?.();
  }} {...props} target={target} className={className} data-sentry-element="Link" data-sentry-component="CustomNextLink" data-sentry-source-file="CustomNextLink.tsx">
            {children}
        </Link>;
}