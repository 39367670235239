'use client';

// Error boundaries must be Client Components
import { Typography } from '@material-tailwind/react';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import CustomNextLink from '@/shared/components/CustomNextLink';
import { BrandIcon } from '@/shared/components/icons/BrandIcon';
import { ROUTES } from '@/shared/constants/routes';
export default function Error({
  error
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
  }, [error]);
  return <div className="flex flex-col justify-center items-center h-screen" data-sentry-component="Error" data-sentry-source-file="error.tsx">
            <div className="flex flex-col items-center justify-center p-5">
                <div className="py-5">
                    <BrandIcon data-sentry-element="BrandIcon" data-sentry-source-file="error.tsx" />
                </div>
                <Typography variant="h3" className="mb-5 text-2xl text-black-500" data-sentry-element="Typography" data-sentry-source-file="error.tsx">
                    An Unexpected Error Occured
                </Typography>
                <Typography variant="h5" className="text-base text-gray-500 font-normal mb-4 text-center" data-sentry-element="Typography" data-sentry-source-file="error.tsx">
                    Oops! The page you&apos;re looking for seems to have an
                    error
                </Typography>
                <CustomNextLink href={ROUTES.dashboard.support} target="_blank" className="text-sm bg-purple-500 text-white py-2 px-4 rounded-md" data-sentry-element="CustomNextLink" data-sentry-source-file="error.tsx">
                    Report this to our support
                </CustomNextLink>
            </div>
        </div>;
}