export const Environments = {
    APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    API: {
        hostname: process.env.NEXT_PUBLIC_API_HOST,
    },
    SOCKET: {
        hostname: process.env.NEXT_PUBLIC_SOCKET_HOST,
    },
    HOMNIFY: {
        profileUrl: process.env.NEXT_PUBLIC_HOMNIFY_PROFILE_URL,
        depositUrl: process.env.NEXT_PUBLIC_HOMNIFY_DEPOSIT_URL,
    },
    SERVER_ACTION: {
        APP_ENV: process.env.APP_ENV,
        API: {
            hostname: process.env.API_HOST,
        },
    },
    TEST_AUTH_URL: process.env.TEST_AUTH_URL,
};
